<template>
  <NewsList />
</template>

<script>
import NewsList from "@/components/news/NewsList"

export default {
  name: "News",
  components: {
    NewsList,
  },
}
</script>

<style scoped></style>
