<template>
  <div>
    <p>
      <a-button v-if="isManufacturerAdmin" type="primary" @click="addNews()">
        <a-icon type="plus" />
        Добавить новость
      </a-button>
    </p>
    <a-spin :spinning="spinning">
      <div>
        <a-row :gutter="[16, 16]">
          <a-col v-for="n in news" :key="n.id" :span="8">
            <a-card>
              <img slot="cover" class="preview-image" :src="n.image ? n.image.original_url : ''" />
              <template slot="actions" class="ant-card-actions">
                <router-link :to="{ name: 'NewsView', params: { id: n.id } }">
                  Подробнее
                </router-link>
                <router-link
                  v-if="isManufacturerAdmin"
                  :to="{ name: 'NewsEdit', params: { id: n.id } }"
                >
                  <a-icon key="edit" type="edit" title="Редактировать" />
                </router-link>
                <a-popconfirm
                  title="Удалить новость"
                  ok-text="Да"
                  cancel-text="Нет"
                  @confirm="confirmDelete(n.id)"
                >
                  <a-icon
                    v-if="isManufacturerAdmin"
                    key="delete"
                    type="delete"
                    title="Удалить"
                  />
                </a-popconfirm>
              </template>
              <a-card-meta
                :title="n.subject"
                :description="n.announcement"
              ></a-card-meta>
            </a-card>
          </a-col>
        </a-row>
        <a-pagination
          v-model="currentPage"
          :total="parseInt(listMeta.total)"
          :pageSize="parseInt(listMeta.per_page)"
          show-less-items
        />
      </div>
    </a-spin>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import NewsService from "@/services/api/v1/NewsService"
import { message } from "ant-design-vue"
export default {
  name: "NewsList",

  components: {},

  data() {
    return {
      files: [],
      imageIndex: 0,
      spinning: false,
    }
  },

  computed: {
    ...mapGetters({
      isDealer: "auth/isDealer",
      isSupplier: "auth/isSupplier",
      isManufacturer: "auth/isManufacturer",
      isManufacturerAdmin: "auth/isManufacturerAdmin",
      news: "news/news",
      listMeta: "news/listMeta",
    }),
    totalCount() {
      return this.listMeta.total
    },
    currentPage: {
      get: function () {
        return this.listMeta.current_page
      },
      set: function (val) {
        this.$store.commit("news/SET_CURRENT_PAGE", val)
        this.loadNews()
        return val
      },
    },
  },

  methods: {
    ...mapActions({
      getNews: "news/getNewsList",
    }),
    addNews() {
      this.$router.push({ name: "NewsCreate" })
    },
    async confirmDelete(id) {
      this.spinning = true
      await new NewsService().deleteNews(id)
      this.spinning = false
      message.success("Новость удалена")
      this.loadNews()
    },
    async loadNews() {
      this.spinning = true
      let cabinet = -1
      if (this.isDealer) cabinet = 0
      if (this.isSupplier) cabinet = 1
      if (this.isManufacturer) cabinet = -1
      await this.getNews(cabinet)
      this.spinning = false
    },
  },

  mounted() {
    this.loadNews()
  },

  watch: {},
}
</script>

<style lang="scss" scoped>
img.preview-image {
  max-height: 340px;
  object-fit: cover;
}
</style>
